import React from "react"
import { useIntl } from "react-intl"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { getHomePageUrl, getPopularUrl } from "../../utils/i18nUrls"
import { Category } from "../Category/Category"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"

export const Home: React.FC<{
  page: { title: string; description: string; tab: string }
  articles: ArticleEntity[]
  lang: string
  logo: any
  hideAuthorNameInArticles?: boolean
  categories: ArticleEntity["category"][]
}> = props => {
  const intl = useIntl()
  return (
    <>
      <StaticPageContainer>
        <StaticPageHeading
          categories={[
            {
              id: "recents",
              url: getHomePageUrl(props.lang),
              hidden: false,
              lang: props.lang,
              name: intl.formatMessage({
                id: "home/tabs/recents",
              }),
            },
            {
              id: "popular",
              url: getPopularUrl(props.lang),
              hidden: false,
              lang: props.lang,
              name: intl.formatMessage({
                id: "home/tabs/popular",
              }),
            },
            ...props.categories,
          ]}
          title={props.page.title}
          lang={props.lang}
          description={props.page.description}
          tab={props.page.tab}
          logo={props.page.tab === "recents" ? props.logo : null}
        />
        <Category
          articles={props.articles}
          hideAuthorNameInArticles={props.hideAuthorNameInArticles}
        />
      </StaticPageContainer>
    </>
  )
}
