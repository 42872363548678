import React from "react"
import { Home } from "../components/Home/Home"
import { Layout } from "../components/Layout/Layout"
import { Seo } from "../components/Seo/Seo"
import { buildFaq } from "../utils/buildFaq"

const HomeTemplate = props => {
  return (
    <>
      <Seo
        title={props.pageContext.meta?.title}
        description={props.pageContext.meta?.description}
        indexable={props.pageContext.meta?.indexable}
        domain={props.pageContext.configuration.domain}
        image={props.pageContext.meta?.image?.src?.publicURL}
        lang={props.pageContext.language}
        faq={buildFaq(props.pageContext.page.content)}
      />

      <Layout
        theme={props.pageContext.theme}
        header={props.pageContext.header}
        aside={props.pageContext.aside}
        drawer={props.pageContext.drawer}
        language={props.pageContext.language}
      >
        <Home
          page={props.pageContext.page}
          articles={props.pageContext.articles}
          categories={props.pageContext.categories}
          lang={props.pageContext.language}
          logo={props.pageContext.header.logo}
          hideAuthorNameInArticles={props.pageContext.page.isAuthorPage}
        />
      </Layout>
    </>
  )
}

export default HomeTemplate
